<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div v-if="!isLoading && service">
      <h1 v-if="id">Edit Service</h1>
      <h1 v-else>Create Service</h1>
      <p v-if="id">Set your standard business hours. These times will be used for your appointment calendar.</p>
      <p v-else>Set your standard business hours. These times will be used for your appointment calendar.</p>
      <hr>
      <b-field class="modal-label" label="Name">
        <b-input v-model="service.name" type="text"/>
      </b-field>
      <b-field class="modal-label" label="Duration (minutes)">
        <b-input v-model="service.duration" type="text"/>
      </b-field>
      <b-field class="modal-label" label="Price">
        <b-input v-model="service.price" type="text"/>
      </b-field>
      <b-field class="modal-label" label="Category">
        <b-select placeholder="Select a service category" v-model="service.service_category_id">
          <option v-for="option in serviceCategories" :value="option.id" :key="option.id">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-field class="modal-label" label="Description">
        <b-input v-model="service.description" type="textarea"/>
      </b-field>
      <hr>
      <div class="level is-mobile">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-light" @click="cancel">Cancel</a>
          </div>
          <div class="level-item">
            <button class="button is-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'ServiceEdit',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Services', route: 'settings-services' },
        { name: 'Edit Service', route: '' }
      ],
      isLoading: false,
      service: {
        name: '',
        description: '',
        duration: 60,
        price: 0,
        service_category_id: null
      }
    }
  },
  computed: {
    serviceCategories () {
      return this.$store.getters['settings/serviceCategories']
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    reloadService () {
      if (this.id) {
        this.isLoading = true
        this.$store.dispatch('settings/fetchService', this.id)
          .then((service) => {
            this.service = service
            this.isLoading = false
          })
      }
    },
    save () {
      this.$store.dispatch('settings/saveService', this.service)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Service saved.',
            type: 'is-success'
          })
          this.$router.push({ name: 'settings-services' })
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 5000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000,
              queue: false
            })
          }
        })
    }
  },
  mounted () {
    this.reloadService()
    this.$store.dispatch('settings/fetchServiceCategories')
  },
  components: { Breadcrumbs },
  props: ['id']
}
</script>
